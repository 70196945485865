import React, { useState } from 'react'
import { Link as RouterLink } from 'gatsby'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

import { Box, Heading, Text, Space, Link } from 'components'

const Article = ({
  id,
  slug,
  title,
  image,
  date,
  body: {
    json: body,
    fields: {
      abstract,
      readingTime: { minutes: readingTime },
    },
  },

  isInline,
}) => {
  const [isHover, setHover] = useState(false)

  return (
    <Box
      sx={{
        position: 'relative',
        display: isInline ? [null, null, null, 'flex'] : null,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(true)}
      onTouchEnd={() => setHover(false)}
    >
      <Box
        sx={{
          position: 'relative',
          flexShrink: 0,
          width: '100%',
          maxWidth: isInline ? [null, null, null, 168] : null,
          mt: isInline ? [null, null, null, 2] : null,
          mb: isInline ? [4, null, null, 0] : null,
        }}
      >
        <Box
          sx={{
            borderRadius: 'large',
            overflow: 'hidden',
            position: 'relative',
            pb: isInline ? ['60%', null, null, '100%'] : '60%',
            transition: 'opacity 100ms ease',

            '> *': {
              position: 'absolute',
              height: '100%',
              width: '100%',
              top: '0',
              right: '0',
              bottom: '0',
              left: '0',
            },

            ...(isHover && {
              opacity: 0.8,
            }),
          }}
        >
          <Img
            alt={title}
            fluid={image.fluid}
            style={{ position: 'absolute' }}
          />
        </Box>
      </Box>
      <Box sx={{ pl: isInline ? [null, null, null, 4] : 0 }}>
        {!isInline && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 2 }}>
            <Box flexShrink={0}>
              <Text
                variant="muted"
                sx={{ whiteSpace: 'nowrap', pt: '6px', fontSize: '12px' }}
              >
                {date}
              </Text>
            </Box>
          </Box>
        )}

        <Heading.H4 as="h2" sx={{ mb: 3 }}>
          {title}
        </Heading.H4>

        <Text sx={{ fontSize: 1, mb: 3 }}>{abstract}</Text>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
          <Text
            variant="muted"
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px',
              fontWeight: 'bold',
              flexGrow: 1,
            }}
          >
            <Box
              as="svg"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              sx={{ mr: 2, width: 11, height: 12 }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.5 0.5C2.45 0.5 0 2.95 0 6C0 9.05 2.45 11.5 5.5 11.5C8.55 11.5 11 9.05 11 6C11 2.95 8.55 0.5 5.5 0.5ZM5.5 10.5C3 10.5 1 8.5 1 6C1 3.5 3 1.5 5.5 1.5C8 1.5 10 3.5 10 6C10 8.5 8 10.5 5.5 10.5ZM7.35 7.85C7.55 7.65 7.55 7.35 7.35 7.15L6 5.8V3C6 2.7 5.8 2.5 5.5 2.5C5.2 2.5 5 2.7 5 3V6C5 6.15 5.05 6.25 5.15 6.35L6.65 7.85C6.75 7.95 6.85 8 7 8C7.15 8 7.25 7.95 7.35 7.85Z"
                fill="currentColor"
              />
            </Box>
            Lesezeit {readingTime} Minuten
          </Text>

          <Link
            as={RouterLink}
            to={`/blog/${slug}`}
            sx={{
              flexShrink: 0,
              display: 'inline-block',
              fontSize: 1,
              px: 4,
              py: 2,
              lineHeight: 1,
              borderRadius: 'default',
              border: '1px solid',
              borderColor: 'colorBlue100',
              color: 'colorBlue100',
              textDecoration: 'none',
              transition: 'all 100ms ease',

              position: 'static',

              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },

              '&:hover': {
                bg: 'colorBlue100',
                color: 'white',
              },
            }}
          >
            Weiterlesen
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default Article

export const query = graphql`
  fragment BlogArticle on ContentfulBlogArticle {
    title
    slug
    isHidden
    seoTitle
    seoDescription {
      seoDescription
    }
    image {
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    countries
    date(formatString: "DD. MMMM YYYY", locale: "de")
    id
    body {
      json
      fields {
        abstract
        readingTime {
          minutes
        }
      }
    }
  }
`
