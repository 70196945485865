import React from 'react'
import { graphql } from 'gatsby'

import { Section, Container, Row, Column, Heading, Box, Text } from 'components'

import Layout from 'components/Layout'

import Article from '../components/Article'
import Sidebar from '../components/Sidebar'
import LeadForm from '../components/LeadForm'

import { useLocation } from 'context/location'

const Page = ({ data }) => {
  const {
    articles: { nodes: articles },
  } = data

  const { countryCode } = useLocation()

  const countryCodeToUse =
    countryCode !== 'DE' && countryCode !== 'AT' ? 'AT' : countryCode

  const countrySpecificArticles = articles
    .filter(({ countries }) => {
      if (!countries) {
        return true
      }
      if (countries.length === 0) {
        return true
      }

      return countries.includes(countryCodeToUse.toLowerCase())
    })
    .filter(({ isHidden }) => !isHidden)

  const featuredLargeArticle = countrySpecificArticles?.[0]
  const articlesToShow = countrySpecificArticles?.slice(1)

  return (
    <Layout
      seo={{
        title: 'Bambus Blog',
        description: 'Das Neueste von Bambus und Liquidität für Immobilienbesitzer!',
      }}
    >
      <Section spacing="small">
        <Container>
          <Row flexWrap="wrap">
            <Column size={[1, 1, 7 / 12, 8 / 12]}>
              <Heading.H2
                as="h1"
                sx={{
                  pt: 4,
                  pb: 6,
                  '@media (max-width: 320px)': {
                    fontSize: 30,
                  },
                }}
              >
                Blog
              </Heading.H2>

              {!featuredLargeArticle && (
                <Text sx={{ textAlign: 'center', mb: 3 }}>
                  Es sind noch keine Artikel vorhanden.
                </Text>
              )}

              {featuredLargeArticle && (
                <Row>
                  <Column size={[1, null, null, null]} sx={{ mb: 8 }}>
                    <Article {...featuredLargeArticle} />
                  </Column>
                </Row>
              )}

              <Box>
                <Box>
                  <Row
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    {articlesToShow?.map((article) => (
                      <Column
                        key={article.id}
                        size={[1, null, null, 1 / 2]}
                        sx={{ mb: 8 }}
                      >
                        <Article {...article} />
                      </Column>
                    ))}
                  </Row>
                </Box>
                <Box
                  sx={{
                    display: ['flex', null, 'none'],
                    justifyContent: 'center',
                    mb: 6,
                  }}
                >
                  <LeadForm />
                </Box>
              </Box>
            </Column>
            <Column
              id="StickySidebarBottomBoundary"
              size={[1, 1, 5 / 12, 4 / 12]}
            >
              <Box sx={{ display: ['none', null, 'block'] }}>
                <Sidebar bottomBoundarySelector="#StickySidebarBottomBoundary"></Sidebar>
              </Box>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    articles: allContentfulBlogArticle(
      filter: { node_locale: { eq: "de" } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...BlogArticle
      }
    }
  }
`
