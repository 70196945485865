import React from 'react'
import Sticky from 'react-stickynode'

import useWindowSize from 'hooks/useWindowSize'

import LeadForm from './LeadForm'

const Sidebar = ({ bottomBoundarySelector }) => {
  const { width: windowWidth } = useWindowSize()

  return (
    <Sticky
      enabled={windowWidth >= 832}
      top={96}
      bottomBoundary={bottomBoundarySelector}
    >
      <LeadForm />
    </Sticky>
  )
}

export default Sidebar
